
import Vue from "vue"
import axios from "axios"
import { variables } from "./plugins/variables"
import { nouveauJeton } from "./plugins/jwt"

export default Vue.extend({
  name: "App",
  data: () => ({
    unsubscribe: null as any,
    drawer: true,
    authentification: false,
    langue: "AL",
    dictionnaire: [] as string[],
    utilisateur: ""
  }),
  methods: {
    initialiser() {
      var url = this.$store.state.ajaxurl + "ajax_appli.php"
      axios
        .get(url, {
          params: {
            action: "SALARIES"
          }
        })
        .then(response => {
          this.$store.commit("majSalaries", response.data.salaries)
        })
    },
    deconnexion() {
      this.$store.commit("majConnexion", false)
      this.$router.push("/login")
    },
    changementLangue() {
      this.choixLangue(this.langue)
      this.$store.commit("majLangue", this.langue)
    },
    applicationMachines() {
      const jwt = nouveauJeton()
      const url = "https://machines.mdsp.link/jwt/" + jwt
      window.open(url, "_blank")
    },
    choixLangue(langue: string) {
      switch (langue) {
        case "FR":
          this.dictionnaire = [
            "Gestion avancée", //0
            "Salariés ...", //1
            "Autorisations ...", //2
            "Machines ...", //3
            "A propos...", //4
            "Transfert Laitier", //5
            "Déconnexion", //6
            "Maintenance", //7
            "Fosse", //8
            "Horamètre", //9
            "Poste" //10
          ]
          break
        case "AL":
          this.dictionnaire = [
            "Erweiterte verwaltung", //0
            "Mitarbeiter ...", //1
            "Berechtigungen...", //2
            "Maschinen...", //3
            "In Bezug auf...", //4
            "Schlackenfahrten flüssig", //5
            "Ausloggen", //6
            "Instandhaltung", //7
            "Grube", //8
            "Betriebsstunden", //9
            "Schicht" //10
          ]
          break
      }
    },
    autTransfertLaitier() {
      return variables.autorisation.includes("Tranfert laitier")
    },
    autAdministration() {
      return variables.autorisation.includes("Administration")
    },
    autMaintenance() {
      return variables.autorisation.includes("Maintenance")
    },
    autMachine() {
      return variables.autorisation.includes("Gestion machine")
    },
    autPneu() {
      return variables.autorisation.includes("Gestion pneu")
    },
    autFosse() {
      return variables.autorisation.includes("Fosse")
    },
    autHorametre() {
      return variables.autorisation.includes("Horametre")
    }
  },
  created() {
    this.choixLangue("AL")
    this.utilisateur = this.$store.state.utilisateur.user
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majConnexion":
          this.authentification = variables.authentifie
          break
        case "majLangue":
          this.langue = this.$store.state.langue
          this.choixLangue(this.langue)
          break
        case "majUtilisateur":
          this.utilisateur = this.$store.state.utilisateur.user
      }
    })
  },
  mounted() {
    this.initialiser()
  },
  beforeDestroy() {
    this.unsubscribe = null
  }
})
