import Vue from "vue"
// @ts-ignore
import Vuetify from "vuetify/lib"
import fr from "vuetify/src/locale/fr"

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { fr },
    current: "fr"
  },
  theme: {
    themes: {
      light: {
        primary: "#4DB6AC",
        secondary: "#B2DFDB",
        accent: "#00796B",
        error: "#E53935"
      }
    }
  }
})
