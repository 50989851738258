import Vue from "vue"
import Vuex from "vuex"
import { IUtilisateur } from "./types"
import { sqlDate, variables } from "../plugins/variables"

var debug = process.env.NODE_ENV === "development"
let cejour = new Date()
let dateDeDebut = sqlDate(new Date(cejour.getFullYear(), cejour.getMonth(), 1))
let dateDeFin = sqlDate(new Date(cejour.getFullYear(), cejour.getMonth() + 1, 0))

var utilisateur = {
  user: "non authentifié",
  ut_id: 0,
  administrateur: false
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    debug: debug,
    ajaxurl: debug ? "http://localhost/SAB/SAB-Intranet/php/" : "/php/",
    utilisateur: utilisateur,
    dateDeDebut: dateDeDebut,
    dateDeFin: dateDeFin,
    authentifie: false,
    langue: "AL",
    pneu: {} as any,
    salaries: [] as IUtilisateur[]
  },
  mutations: {
    majUtilisateur(state, utilisateur) {
      state.utilisateur = utilisateur
    },
    majSalaries(state, salaries) {
      state.salaries = salaries
    },
    majConnexion(state, etatCo) {
      state.authentifie = etatCo
      variables.authentifie = etatCo
    },
    majLangue(state, langue) {
      state.langue = langue
    },
    majPneu(state, pneu) {
      state.pneu = pneu
    }
  },
  actions: {},
  modules: {},
  getters: {}
})
