const rs = require("jsrsasign")
import store from "../store"

export function nouveauJeton(): string {
  const alg = "RS256"
  const pkcs8 = `-----BEGIN PRIVATE KEY-----
  MIIEuwIBADANBgkqhkiG9w0BAQEFAASCBKUwggShAgEAAoIBAQC04poZfBddcL0v
  1rUmRBj44iYmdZRil5euJWWnqwkt4j6BR+eoZ90Au6VgCGi308TXrQRf9O0sm88b
  fxnJ1bUtT2ZLIYyX3Ys3X3A3gB6XuoH3fv24Z2BAQc86k8srIlMPbdgdts8z6v4t
  J25WPamYfwaUnnZsu+jLAFAHdQiwWNOhNZwCe9YDnVddv+d5N7FsUgxWrjpCTb5x
  XUCTJjv1Bdq/i1G/QBc5sdV5s0fdgWK1MMa4iZZgTbNdbRomEUA7+sMYMB07WYGv
  Td6buCz8ekCW6A3xxJLN9GrbKqgo35sYBik+9Cf8mg53mY8hXUCN48Q49kJYnsNz
  XoWEbm6BAgMBAAECgf9QUwphGDnTlhnhXrEp+4fViVGtfKBzs1H5a/8JxztX6p/u
  VJc5PRSsvqcxff7UABNdsw54dss4soOXSYhNDQJb4mZnmYn4Q3TgXC/KRw7xrux8
  TMVenf23RVOGpAOI6dgP3wjfQFp9BReUUrQVUh4Jdxk6zsZq38EvHouxISSXtbxd
  JAtbeqB8n+Yjr6Sd5YCsGhqhAvGfNB0SuXrb9u4QRW2z3eZs7LP4D2UAwT30YyK0
  hLCAnoCs90ZYi3fvElS1IrqPGsWexyhOJDrD16o9R2usmAo1NGduB2L+wqYYYTFf
  MJ/p0KpEWCqoV3K71egI6oUXGOT4OiNlH9qwL98CgYEA9dLvukuuHVhDlIOfhcXE
  W40t/hO4Lw6xhrOxylWK8cWG1zA7JTepQKYvGocmV/yldGEGPvMM7ued5yj/0+yX
  nooJCT8b+Yv3S47mTxjd1YQ25+oDEBaQ+rM2L0SXF2l4r8QHT5kLUMbJwTLKitV/
  L+Yq1MFJE2TZAfznOL/PxK8CgYEAvF99xZHMoYLDCeissa2G7JgSpYo//ODG4g6x
  e+Lwal2Do0yGMWYaYyZq4X9D0BWxP7NsRwin8NAsEiFiiQK5n7xbmzaz/tdlX5lv
  q8ScKaIm1mAXsGhQ0lnYVo6RgEk3L3HXWPRzxRzREMUl/RBfO9XgbNfVwI+YrfG6
  mkQrK88CgYB2z3N0K/3ESxOmpRMluZcU1KBlC6zaeZSN0S3d6AhWoCxytunvK/7O
  s0NS4/1BS2fAXuCA7kHS+fkUb3luOQxy4K5LXMgtjVuBllhz5EXbW6KZ5j2CuhVq
  RdGreNA5zY06DlmmPAb/DIUYrm68OUHWqb0LxTuHdxd3YX9zqylFGwKBgECLl9Ty
  uGM/IwVqLIJVKz42fW0rifFkM8GRt07z290WXdy+g9y5V6FRsJ3DtcC/LhTdreRJ
  GwJw0ikEN3cq79/D8r+kZtiThbDUKmd43umFHjmmg3d2xvrMvkwqXcDPVZF0OI3K
  dUC9NwXAB9xGl9sw1c11x1BL48z2XzIs2X4RAoGBAMmuGqkHA/+wK8NhXHbeuglM
  WRVZy6iwiAIcuSFuEuPS5FwlzMxkYBTLU9jNg/DPxvF9QRLbTxSH0nnOcrcFw85w
  2tNVTv9Yw4t17ROOXF+L+dwj2//fTn1KnaEq7ODneLYKWycHbYbKkNJjoqY8v6ff
  nBqmTsK9d+8CtKdy5qV4
  -----END PRIVATE KEY-----`

  const prvKey = rs.KEYUTIL.getKey(pkcs8)
  const oHeader = { alg: "RS256", typ: "JWT" }
  const sHeader = JSON.stringify(oHeader)
  const tNow = rs.jws.IntDate.get("now")
  const tEnd = rs.jws.IntDate.get("now + 1hour")

  const payload = {
    base: "SAB",
    societe: "SAB",
    lang: store.state.langue,
    nbf: tNow,
    iat: tNow,
    exp: tEnd
  }
  const sPayload = JSON.stringify(payload)
  const sJWT: string = rs.jws.JWS.sign("RS256", sHeader, sPayload, prvKey)

  return sJWT
}
