import Vue from "vue"
import VueRouter from "vue-router"
import { variables } from "../plugins/variables"

Vue.use(VueRouter)

const routes = [
  {
    path: "/TransfertLaitier",
    name: "TransfertLaitier",
    component: () => import("@/views/TransfertLaitier.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/machines",
    name: "Machine",
    component: () => import("@/views/Machine.vue")
  },
  {
    path: "/salaries",
    name: "Salaries",
    component: () => import("@/views/Salaries.vue")
  },
  {
    path: "/autorisations",
    name: "Autorisations",
    component: () => import("@/views/Autorisation.vue")
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/views/Maintenance.vue")
  },
  {
    path: "/pneu",
    name: "Pneu",
    component: () => import("@/views/Pneu.vue")
  },
  {
    path: "/fosse",
    name: "Fosse",
    component: () => import("@/views/Fosse.vue")
  },
  {
    path: "/horametre",
    name: "Horametre",
    component: () => import("@/views/Horametre.vue")
  },
  {
    path: "/poste",
    name: "Poste",
    component: () => import("@/views/Poste.vue")
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/About.vue")
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path.startsWith("/login")) {
    next()
  } else {
    if (!variables.authentifie) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  }
})
export default router
