export var variables = {
  authentifie: false,
  autorisation: [] as any
}

import store from "../store/index"
export function sqlDate(dte: Date): string {
  if (dte === null) return ""
  if (dte === undefined) return ""
  var y = dte.getFullYear()
  var mo = dte.getMonth() + 1
  var d = dte.getDate()
  var mmo = (mo < 10 ? "0" : "") + mo
  var dd = (d < 10 ? "0" : "") + d
  return `${y}-${mmo}-${dd}`
}
export function dateFrancaise(dte: string | Date): string {
  if (dte === null) return ""
  if (dte === undefined) return ""
  dte = new Date(dte)
  const y = dte.getFullYear()
  const mo = dte.getMonth() + 1
  const d = dte.getDate()
  const mmo = (mo < 10 ? "0" : "") + mo
  const dd = (d < 10 ? "0" : "") + d
  if (store.state.langue == "FR") {
    return `${dd}/${mmo}/${y}`
  } else {
    return `${dd}.${mmo}.${y}`
  }
}
export function sqlDatetime(dte: Date): string {
  if (dte === null) return ""
  if (dte === undefined) return ""
  var y = dte.getFullYear()
  var mo = dte.getMonth() + 1
  var d = dte.getDate()
  var mmo = (mo < 10 ? "0" : "") + mo
  var dd = (d < 10 ? "0" : "") + d

  var h = dte.getHours()
  var mn = dte.getMinutes()
  var hh = (h < 10 ? "0" : "") + h
  var mmn = (mn < 10 ? "0" : "") + mn

  return `${y}-${mmo}-${dd} ${hh}:${mmn}`
}

export function dateHeureFrancaise(dte: Date | string): string {
  if (dte === null) return ""
  if (dte === undefined) return ""
  dte = new Date(dte)
  var y = dte.getFullYear()
  var mo = dte.getMonth() + 1
  var d = dte.getDate()
  var mmo = (mo < 10 ? "0" : "") + mo
  var dd = (d < 10 ? "0" : "") + d

  var h = dte.getHours()
  var mn = dte.getMinutes()
  var hh = (h < 10 ? "0" : "") + h
  var mmn = (mn < 10 ? "0" : "") + mn

  if (store.state.langue == "FR") {
    return `${dd}/${mmo}/${y} ${hh}:${mmn}`
  } else {
    return `${dd}.${mmo}.${y} ${hh}:${mmn}`
  }
}

export function datetimeFromString(stdatetime: string): Date | null {
  if (stdatetime == null) return new Date()
  const regex1 = /(\d*)-(\d*)-(\d*)\s+(\d*):(\d*)/g
  let dte = regex1.exec(stdatetime)
  if (dte) {
    var date = new Date(parseInt(dte[1]), parseInt(dte[2]) - 1, parseInt(dte[3]), parseInt(dte[4]), parseInt(dte[5]))
    return date
  }
  return null
}
export function dateFromString(stdate: string): Date {
  if (stdate == null) return new Date()
  const [year, month, day] = stdate.split("-")
  var date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), 12)
  return date
}
export function sqlFormat(annee: number, mois: number, jour: number) {
  let a = annee.toString()
  let m = mois.toString()
  if (m.length === 1) m = "0" + m
  let j = jour.toString()
  if (j.length === 1) j = "0" + j
  return a + "-" + m + "-" + j
}
export function dateVersChaine(dte: Date) {
  return dte.toISOString().substr(0, 10)
}
export function cleControle(): string {
  // la date est codée en base 31
  var dte = new Date()
  dte.setHours(12)
  var n = ((dte.getMonth() + 1) * 100 + dte.getDate()) * 10000 + dte.getFullYear()
  var cle = n.toString(31)
  return cle
}
